/* UTILITIES */
* { 
    margin: 0; 
    padding: 0; 
    box-sizing: border-box; 
} 

body { 
    font-family: 'Manrope'; 
} 

a { 
    text-decoration: none; 
} 

ul {
    text-align: center;
    padding-left: 0;
}

li { 
    list-style: none; 
}

/* NAVBAR STYLING STARTS */
.navbar {
    display: block;
    padding: 10px;
    padding-top: 15px;
    background-color: #111;
    color: #fff;

    z-index: 999;
    position: fixed;
    right: 0;
    left: 0;
    right: 0;
 
   -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; 
    animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; 
    
}

.logo {
    font-size: 32px;
}

.menu {
    display: flex;
    flex: 1;
    gap: 1.5em;
    font-size: 18px;
    flex-wrap: wrap;

    text-align: center;
    
    
}

.menu li:hover {
    background-color: #00192B;
    border-radius: 5px;
    transition: 0.3s ease;
    color: #7dafc9;
}

.menu li {
    padding: 5px 14px;
}
   
.services {
    position: relative;
}
   
.dropdown {
    background-color: #111;
    padding: 1em 0;
    position: absolute; /*WITH RESPECT TO PARENT*/
    display: none;
    border-radius: 8px;
    top: 35px;
    color: #fff;
    z-index: 999;
}
   
.dropdown li + li {
    margin-top: 10px;
}

.dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
}

.dropdown li:hover {
    background-color: #00192B;
}

.services:hover .dropdown {
    display: block;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-13 14:33:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

