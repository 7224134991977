@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.projects{
    background: black;
    padding-top: 15px;
}

.projecthead{
    color: #00192B;
    -webkit-text-stroke:  1px;
    background-color: #7DAFC9;
    padding-top: 95px;
    text-align: center;
    font-size: 50px; 
    line-height: 3rem;
    padding-bottom: .5rem;
    
}


.styling{
    text-align: center;
    display: block;
    
}

.carousel-caption {
    position: static;
    left: auto;
    right: auto;
}

.carousel-item{
    padding-top: -100px;
}

.projectbot{
    background-color: #7DAFC9;
    padding-bottom: 2rem;
}

.carousel{
    padding-top: -100px;
}

.carousel-inner{
    height: 525px;
    background: #7DAFC9;
}


.container{
    display: block;
}

.hover:hover{
    opacity: .7;
    
}

.hover:hover .carousel-caption{
    color: #00192B;
}


.hover{
    font-size: 200px;
    color: #00192B;
    
}

.hover h3{
    font-size: 40px;
    
}

.hover p{
    font-size: 20px;
    max-width: 700px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.carousel-caption {
    position: static;
    top: 300px;
}

@media (max-width: 500px) {
    .carousel-inner{
        height: 625px;

    }
}

@media (max-width: 400px) {
    .carousel-inner{
        height: 675px;

    }
}

@media (max-width: 683px) {
    .projecthead{
        padding-top: 174px;
    }
}



/* UTILITIES */
* { 
    margin: 0; 
    padding: 0; 
    box-sizing: border-box; 
} 

body { 
    font-family: 'Manrope'; 
} 

a { 
    text-decoration: none; 
} 

ul {
    text-align: center;
    padding-left: 0;
}

li { 
    list-style: none; 
}

/* NAVBAR STYLING STARTS */
.navbar {
    display: block;
    padding: 10px;
    padding-top: 15px;
    background-color: #111;
    color: #fff;

    z-index: 999;
    position: fixed;
    right: 0;
    left: 0;
    right: 0;
 
   -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; 
    animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; 
    
}

.logo {
    font-size: 32px;
}

.menu {
    display: flex;
    flex: 1 1;
    grid-gap: 1.5em;
    gap: 1.5em;
    font-size: 18px;
    flex-wrap: wrap;

    text-align: center;
    
    
}

.menu li:hover {
    background-color: #00192B;
    border-radius: 5px;
    transition: 0.3s ease;
    color: #7dafc9;
}

.menu li {
    padding: 5px 14px;
}
   
.services {
    position: relative;
}
   
.dropdown {
    background-color: #111;
    padding: 1em 0;
    position: absolute; /*WITH RESPECT TO PARENT*/
    display: none;
    border-radius: 8px;
    top: 35px;
    color: #fff;
    z-index: 999;
}
   
.dropdown li + li {
    margin-top: 10px;
}

.dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
}

.dropdown li:hover {
    background-color: #00192B;
}

.services:hover .dropdown {
    display: block;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-13 14:33:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }


.Border{
    background: black;
    padding-top: 15px;

}

.Header h1{
    
    font-size: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    padding-top: 100px;
    
}

.Header h1 h2{
    font-size: 50px;
}

.Header h1 h6{
    font-size: 25px;
}

.Header h6{
    color: #7DAFC9;
}

.Proficiency-List-Lang h1 h2{
    font-size: 50px;
}

.Proficiency-List-Lang h1 h6{
    font-size: 25px;
    
}

.Prof-Containers{
    display: block;
}

.Proficiency-List-Lang{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1 1;
    
    font-size: 100px;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}


.Proficiency-List-Lang h5{
    font-size: 3rem;
    font-weight: bold;
    

    text-align: center;
    margin-left: auto;
    margin-right: auto;

    line-height: 2.5rem;
}


.Proficiency-List-Lang p{
    display: block;

    -webkit-margin-before: 1em;

            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    
    line-height: 1.6;
    font-size: 1rem; 
    max-width: 350px;

}

.Proficiency-List-Lang li{
    padding-right: 15px;
    padding-left: 15px;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.Proficiency-List-Lang h4{
    display: none;
    line-height: 2.5rem;
    font-size: 30px;
    color: #7DAFC9;
}


.Proficiency-List-Lang h1{
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    padding-top: 25px;
    
}

.Proficiency-List-Lang h6{
    font-size: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #7DAFC9;
    

    
}

.Proficiency-List-Lang h3:hover h4{
    display: block;
}

.Proficiency-List-Lang h3:hover h5{
    display: none;
}

.Proficiency-List-Lang h2{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.Proficiency-List-Skills{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1 1;
    
    font-size: 100px;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

}


.Proficiency-List-Skills h3{
    font-size: 3rem;
    font-weight: bold;
    

    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.Proficiency-List-Skills p{
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    -webkit-margin-before: 1em;

            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    
    line-height: 1.6;
    font-size: 1rem; 
    max-width: 350px;

}

.Proficiency-List-Skills li{
    padding-right: 15px;
    padding-left: 15px;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.Proficiency-List-Skills h4{
    display: none;
    line-height: 1.6;
    font-size: 30px;
    color: #7DAFC9;
}

.Proficiency-List-Skills h1{
    font-size: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    padding-top: 25px;
    padding-bottom: 25px;
}

.Proficiency-List-Skills h6{
    font-size: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #7DAFC9;
    width: 100vw;
    
}

.Border2{
    background: #7DAFC9;
    padding: 15px;
}


@media (max-width: 683px) {
    .Header h1{
        padding-top: 190px;
    }
}
    
* {
    box-sizing: border-box;
    font-family: Georgia, Times, 'Times New Roman', serif
}

.Danzi-header-main{
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center;
    padding-top: 142px; 

    color: white;
    letter-spacing: .3px;
}

.about h1{
    font-size: 50px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 2rem;
    
}

.Danzi-header-image{
    object-fit: cover;
    height: 290px;
    width: 250px;
    border-radius: 10%;
    object-position: 50% 0%;
    object-fit: cover;

    padding: 5px;
    background-color: white;
    
    
}

.Danzi_header-p{
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 30px;
    font-size: 20px;
    padding-top: 3rem;
    padding-bottom: 5rem;
    
}

@media (max-width: 683px) {
    .Danzi-header-main{
        padding-top: 205px;

    }
}


.Lining{
        background: black;
        padding-top: 15px;
}

.Footer-Container{
    background-color: #7DAFC9;
    color: #7DAFC9;
    margin-left: auto; 
    margin-right: auto; 
    text-align: center;



}

.Comment{
    color: whitesmoke;
    padding-bottom: 5px;
    
}

.Danzi-navbar-image{
    width: 45px;
    padding-top: 5px; 
}
*{
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body{
    margin: 0;
    padding: 0;
}

a{
    color: unset;
    text-decoration: none;
}

.gradient_bg{
    background-color: #00192B;
}


.gradient_text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    padding: 4rem 6rem;
}

.roll-in-left {
	-webkit-animation: roll-in-left 0.65s ease-in forwards;
	        animation: roll-in-left 0.65s ease-in forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-11 17:36:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes roll-in-left {
    0% {
      -webkit-transform: translateX(-800px) rotate(-540deg);
              transform: translateX(-800px) rotate(-540deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }
  @keyframes roll-in-left {
    0% {
      -webkit-transform: translateX(-800px) rotate(-540deg);
              transform: translateX(-800px) rotate(-540deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }



  @media screen and (max-width: 700px){
    .section_padding{
        padding: 4rem;
    }

    .section_margin{
        margin: 4rem;
    }


}

@media screen and (max-width: 5500px){
    .section_padding{
        padding: 4rem 2 rem;
    }

    .section_margin{
        margin: 4rem 2 rem;
    }

}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
