*{
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body{
    margin: 0;
    padding: 0;
}

a{
    color: unset;
    text-decoration: none;
}

.gradient_bg{
    background-color: #00192B;
}


.gradient_text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    padding: 4rem 6rem;
}

.roll-in-left {
	-webkit-animation: roll-in-left 0.65s ease-in forwards;
	        animation: roll-in-left 0.65s ease-in forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-11 17:36:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes roll-in-left {
    0% {
      -webkit-transform: translateX(-800px) rotate(-540deg);
              transform: translateX(-800px) rotate(-540deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }
  @keyframes roll-in-left {
    0% {
      -webkit-transform: translateX(-800px) rotate(-540deg);
              transform: translateX(-800px) rotate(-540deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotate(0deg);
              transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }



  @media screen and (max-width: 700px){
    .section_padding{
        padding: 4rem;
    }

    .section_margin{
        margin: 4rem;
    }


}

@media screen and (max-width: 5500px){
    .section_padding{
        padding: 4rem 2 rem;
    }

    .section_margin{
        margin: 4rem 2 rem;
    }

}